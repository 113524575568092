<template>
  <div class="viewTerminal">
    <van-nav-bar
      title="终端录入信息"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <form action="/">
      <van-search
        v-model="formData.phone"
        show-action
        placeholder="请输入录入人手机号"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template></van-search
      >
    </form>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      v-if="!noResult"
      @load="onLoad"
    >
      <div class="contentBox">
        <div v-for="(item, index) in dataList" :key="index" class="messagebox">
          <div class="cb-text">飞控序列号：{{ item.pilotCode }}</div>
          <div class="cb-text">无人机型号：{{ item.pilotTypeName || "" }}</div>
          <div class="cb-text">北斗终端号：{{ item.beidouCode }}</div>
          <div class="cb-text">录入人姓名：{{ item.createBy }}</div>
          <div class="cb-text">录入人手机号：{{ item.phone }}</div>
          <div class="cb-text">接收人姓名：{{ item.recipientName || "" }}</div>
          <div class="cb-text">接收人手机号：{{ item.recipientPhone || "" }}</div>
          <div class="cb-text">出厂编号：{{ item.manufacturingCode || "" }}</div>
          <div style="margin-bottom: 10px" class="cb-text">
            出厂厂家：{{ item.manufacturers || "" }}
          </div>
          <van-button @click="deleteZd(item)" block type="danger">删除终端</van-button>
        </div>
      </div>
    </van-list>
    <van-empty v-if="noResult" description="暂无数据" />
  </div>
</template>
<script>
import { getBeidou, deleteBeidou } from "@/api/flyOpretor";
export default {
  data() {
    return {
      formData: {
        phone: "",
        pageNum: 1,
        pageSize: 5,
      },
      total: 0,
      dataList: [],
      noResult: true,
      loading: false,
      finished: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/portal/flyOpretor");
    },
    onSearch() {
      let phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!phoneReg.test(this.formData.phone) && this.formData.phone != "") {
        this.$toast("电话号码不正确，请重新输入");
        return;
      }
      this.formData.pageNum = 1;
      this.getBeidou(true);
    },
    getBeidou(flag) {
      //根据flag判断是onload出发，还是搜索按钮触发
      getBeidou(this.formData).then((res) => {
        const { code, rows } = res;
        if (code == 200) {
          this.loading = false;
          if (flag) {
            this.dataList = rows || [];
            this.total = res.total;
          } else {
            if (rows) {
              rows.forEach((item) => {
                this.dataList.push(item);
              });
            }
          }
        }
      });
    },
    onLoad() {
      this.formData.pageNum++;
      this.loading = true;
      setTimeout(() => {
        this.getBeidou();
      }, 1500);
    },
    deleteZd(item) {
      let that = this;
      this.$dialog
        .confirm({
          message: "确定要删除该终端吗",
        })
        .then(() => {
          that.deleteBeidou(item.id);
        })
        .catch(() => {
          // on cancel
        });
    },
    deleteBeidou(ids) {
      console.log("!@#", ids);
      deleteBeidou(ids).then((res) => {
        if (res.code === 200) {
          this.$toast("删除成功");
          this.formData.pageNum = 1;
          this.getBeidou(true);
        } else {
          this.$toast(res.msg || "删除失败");
        }
      });
    },
  },
  created() {
    this.getBeidou(true);
  },
  watch: {
    dataList(newVal) {
      this.noResult = newVal.length > 0 ? false : true;
      this.finished = newVal.length >= this.total ? true : false;
    },
  },
};
</script>
<style lang="scss" scoped>
.viewTerminal {
  overflow: hidden;
  .contentBox {
    width: 100%;
    margin-top: 20px;
    .messagebox {
      width: 100%;
      background-color: #fafafa;
      padding: 0 14px;
      margin-top: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-bottom: 14px;
      .cb-text {
        word-break: break-all;
        width: 100%;
        margin-top: 14px;
        color: #7a7a7a;
      }
    }
  }
  .noResult {
    width: 100%;
    text-align: center;
    color: #acacaa;
    margin-top: 20px;
  }
}
</style>
